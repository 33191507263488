import { FormEvent, useState } from "react"
import { Choice } from "../Models/choice"
import { ChoiceList } from "./choiceList"
import { Poll } from "../Models/poll"
import { savePoll } from "../Helpers/apiHelpers"

export const AddPollForm = () => {
	const choices: Choice[] = []
	const [pollPrompt, setPollPrompt] = useState("")
	const [newChoice, setNewChoiceText] = useState("")
	const [currentChoices, setChoices] = useState(choices)
	const [statusMsg, setStatusMsg] = useState("")

	const updateInputState = (event: FormEvent<HTMLInputElement>) => {
		setNewChoiceText(event.currentTarget.value)
	}

	const updatePromptState = (event: FormEvent<HTMLInputElement>) => {
		setPollPrompt(event.currentTarget.value)
	}

	const addChoice = () => {
		setChoices((oldChoices) => [...oldChoices, { description: newChoice }])
		setNewChoiceText("")
	}

	const createPoll = async () => {
		const pollToSave: Poll = {
			name: pollPrompt,
			choices: currentChoices,
		}

		var poll = await savePoll(pollToSave)
		setStatusMsg(`Poll saved! ID: ${poll.id}`)
	}

	return (
		<div>
			<span className="text-xl text-red-600">{statusMsg}</span>
			<br />
			<input onChange={updatePromptState} placeholder="Enter your prompt" className="rounded-lg p-2 w-full" />
			<br />
			<input
				onChange={updateInputState}
				placeholder="Enter a new response"
				className="rounded-lg p-2"
				value={newChoice}
			/>
			<button
				onClick={addChoice}
				className="bg-yellow text-orange p-2 rounded-xl m-2 font-bold text-xl ring-4 ring-orange"
			>
				Add Option
			</button>
			<br />
			<ChoiceList choices={currentChoices} />
			<br />
			<div className="place-items-center grid">
				<button
					onClick={createPoll}
					className="bg-yellow text-orange p-4 rounded-xl m-2 font-bold text-2xl ring-4 ring-orange"
				>
					Save poll
				</button>
			</div>
		</div>
	)
}
