import { getPollData } from "../Helpers/apiHelpers"
import { Poll } from "../Models/poll"

type Props = {
	afterQuery: (poll: Poll) => void
}

export const PollQuery = ({ afterQuery }: Props) => {
	const onClick = () => {
		let pollIdStr = document.getElementById("pollIdToQuery")! as HTMLInputElement
		let pollId = parseInt(pollIdStr.value)

		getPollData(pollId).then((poll) => afterQuery(poll))
	}

	return (
		<div>
			<input id="pollIdToQuery" placeholder="Enter poll ID to search" className="rounded-lg p-2"></input>
			<button
				onClick={onClick}
				className="bg-yellow text-orange p-2 rounded-xl m-2 font-bold text-xl ring-4 ring-orange"
			>
				Search
			</button>
		</div>
	)
}
