import { Poll } from "../Models/poll"
import axios from "axios"
import { Choice } from "../Models/choice"

const baseUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_SQUIRE_API_BASE_URL : ""

export const getPollData = async (pollId: number): Promise<Poll> => {
	const endpoint = `${baseUrl}/api/poll/${pollId}`
	const { data, status } = await axios.get<Poll>(endpoint)

	return data
}

export const savePoll = async (poll: Poll): Promise<Poll> => {
	const endpoint = `${baseUrl}/api/poll`
	const { data, status } = await axios.post<Poll>(endpoint, poll)

	return data
}

export const sendVote = async (pollId: number, choiceId: number) => {
	const endpoint = `${baseUrl}/api/poll/${pollId}/vote/${choiceId}`
	const { data, status } = await axios.post<Choice>(endpoint)

	return data
}
