import { useState } from "react"
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom"
import { getPollData } from "../Helpers/apiHelpers"
import { Poll } from "../Models/poll"
import { PollInfo } from "./pollInfo"
import { PollQuery } from "./pollQuery"

export const pollLoader = async ({ params }: LoaderFunctionArgs) => {
	if (params.pollId === null) return null

	const pollId = parseInt(params.pollId!)
	return getPollData(pollId)
}

export const PollViewer = () => {
	const urlPoll = useLoaderData() as Poll
	const [currentPoll, setCurrentPoll] = useState(urlPoll)
	const [currentChoices, setCurrentChoices] = useState(urlPoll?.choices)

	const setNewPollData = async (poll: Poll) => {
		setCurrentPoll(poll)
		setCurrentChoices(poll.choices)
	}

	return (
		<div className="w-4/5 grid place-items-center">
			<PollQuery afterQuery={setNewPollData}></PollQuery>
			{currentPoll && currentChoices && <PollInfo poll={currentPoll} choices={currentChoices}></PollInfo>}
		</div>
	)
}
