import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Root from "./root"
import ErrorPage from "./error-page"
import { pollLoader, PollViewer } from "./Components/pollViewer"
import { AddPollForm } from "./Components/addPoll"

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/view/:pollId",
				element: <PollViewer />,
				loader: pollLoader,
			},
			{
				path: "/view",
				element: <PollViewer />,
			},
			{
				path: "/create",
				element: <AddPollForm />,
			},
		],
	},
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
