import { Choice } from "../Models/choice"

type Props = {
	choices: Choice[]
}
export const ChoiceList = ({ choices }: Props) => {
	if (choices.length > 0) {
		return (
			<ul className="border-yellow border-4 rounded-lg text-center">
				{choices.map((choice, index) => (
					<li key={index} className="odd:bg-orange even:bg-yellow text-dark-blue p-4 text-2xl">
						{choice.description}
					</li>
				))}
			</ul>
		)
	} else {
		return <div>No choices to list</div>
	}
}
