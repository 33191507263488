import { useState } from "react"
import { sendVote } from "../Helpers/apiHelpers"
import { Choice } from "../Models/choice"
import { Poll } from "../Models/poll"
import { VoteList } from "./voteList"

type Props = {
	poll: Poll
	choices: Choice[]
}

export const PollInfo = ({ poll, choices }: Props) => {
	const [selectedChoiceId, setSelectedChoiceId] = useState(0)
	const [statusMsg, setStatusMsg] = useState("")

	const castVote = async () => {
		const choice = await sendVote(poll.id!, selectedChoiceId)
		setStatusMsg(`You have voted successfully! ${choice.description} now has ${choice.numberOfVotes} votes`)
		choices.find((x) => x.id === choice.id)!.numberOfVotes = choice.numberOfVotes
	}

	return (
		<div className="w-full">
			<span className="text-xl text-red-600">{statusMsg}</span>
			<div className="border-orange border-4 rounded-lg">
				<div className="text-xs text-right">Poll Id: {poll.id}</div>
				<div className="text-5xl text-center pb-4">{poll.name}</div>
			</div>
			<div className="border-yellow border-4 rounded-lg">
				<VoteList choices={choices} onSelection={setSelectedChoiceId} />
			</div>
			<div className="place-items-center grid">
				<button
					onClick={castVote}
					className="bg-yellow text-orange p-4 rounded-xl m-2 font-bold text-2xl ring-4 ring-orange"
				>
					Vote!
				</button>
			</div>
		</div>
	)
}
