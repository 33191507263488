import { Link, Outlet } from "react-router-dom"

export default function Root() {
	return (
		<div className="bg-dark-blue text-light-blue h-screen flex flex-row">
			<div id="sideNav" className="w-1/6 border-r-8 border-orange h-screen">
				<h1 className="text-6xl underline decoration-4 decoration-orange text-center">SQUIRE</h1>
				<div className="hidden">
					<form id="search-form" role="search">
						<input id="q" aria-label="Search contacts" placeholder="Search" type="search" name="q" />
						<div id="search-spinner" aria-hidden hidden={true} />
						<div className="sr-only" aria-live="polite"></div>
					</form>
					<form method="post">
						<button type="submit">New</button>
					</form>
				</div>
				<nav>
					<ul>
						<li className="bg-yellow text-orange p-2 rounded-xl m-2 font-bold text-center ring-2 ring-orange">
							<Link to={`/create`}>Create Poll</Link>
						</li>
						<li className="bg-yellow text-orange p-2 rounded-xl m-2 font-bold text-center ring-2 ring-orange">
							<Link to={`/view`}>Find Polls</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div id="detail" className="w-5/6 h-screen grid place-items-center">
				<Outlet />
			</div>
		</div>
	)
}
