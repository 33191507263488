import { Choice } from "../Models/choice"

type Props = {
	choices: Choice[]
	onSelection: (selectedChoiceId: number) => void
}

export const VoteList = ({ choices, onSelection }: Props) => {
	const changeSelection = (selectionId: number) => {
		onSelection(selectionId)
	}

	return (
		<div>
			<ul>
				{choices.map((choice, index) => (
					<li key={index} className="odd:bg-orange even:bg-yellow text-dark-blue p-4 text-2xl">
						<div className="flex">
							<div className="w-4/5 pl-36">
								<input
									type="radio"
									value={choice.id}
									onChange={() => changeSelection(choice.id!)}
									name="voteOption"
									className="w-6 h-6 m-3 ring-pale-blue ring-4"
								/>
								{choice.description}
							</div>
							<div className="w-1/5 text-right text-dark-blue">
								<div className="border-4 border-pale-blue bg-light-blue h-full w-1/3 text-center rounded-3xl">
									<span className="p-2">{choice.numberOfVotes}</span>
								</div>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	)
}
